import React from "react";
import styled from "styled-components";
import { StoreContext } from "../../context/StoreContext";
import LineItem from "./line_item";
import LineItemMobile from "./line_item_mobile";
import * as vars from "../variables";

const Cart = () => {

  const { publicUpdateCartState, cartState, checkout, loading } = React.useContext(StoreContext)

  const closeCart = () => {
    publicUpdateCartState(false);
  };

  const Line_item =
    checkout.lineItems.length !== 0 ? (
      <div className="table-container">
        <table className="table is-fullwidth is-hoverable">
          <thead>
            <tr align="center">
              <th>Item Description</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {checkout.lineItems.map((line_item) => {
              return (
                <LineItem key={line_item.id.toString()} line_item={line_item} />
              );
            })}
          </tbody>
        </table>
      </div>
    ) : (
      <div className="empty-cart has-text-centered">
        <p>Your cart is empty</p>
      </div>
    );

    const Line_item_Mobile = checkout.lineItems.length !== 0 ? (
      checkout.lineItems.map(line_item_mobile => {
          return <LineItemMobile key={line_item_mobile.id.toString()} line_item={line_item_mobile} />
      })
      )
      : (
        <div className="empty-cart has-text-centered">
          <p>Your cart is empty</p>
        </div>
      );

  return (
    <StyledCart>
      <div className={cartState ? "cart-container is-open" : "cart-container"}>
        <div className="close-cart-area" title="Close Cart" alt="Close Cart" onClick={() => closeCart()} onKeyDown={() => closeCart()} role="button" tabIndex={0}></div>
        <div className="cart-modal">
          <div className={loading ? "adding-view is-visible" : "adding-view"}><p>Updating Cart..</p></div>
          <div className="container">
              <div>
                <div className="cart-header">
                  <p className="cart-title">Your Cart</p>
                  <div className="close-cart" title="Close Cart" alt="Close Cart" onClick={() => closeCart()} onKeyDown={() => closeCart()} tabIndex={0} role="button"></div>
                  </div>
                  <div className="Line_item">{Line_item}</div>
                  <div className="Line_itemMobile">
                    {Line_item_Mobile}
                  </div>
              </div>
              {checkout.lineItems.length !== 0 ? (
                  <div className="order-summary-container">
                    <div>
                      <h2 className="is-size-6">
                        Order Summary
                      </h2>
                      <hr /> 
                      <div className="row">
                        <p className="has-text-right shippingText">
                          Shipping calculated at checkout
                        </p>
                      </div>

                      <div className="row">
                        <span>
                          Total :
                        </span>
                        <span>
                        {`$${(checkout.subtotalPrice.amount * 1).toFixed(2)}`}
                        </span>
                      </div>                      
                      <hr />
                      <a
                        className="checkoutButton button"
                        href={checkout.webUrl}
                        onClick={() => {window.dataLayer.push({'event': 'checkout_click'})}}
                      >
                        Checkout
                      </a>                      
                    </div>
                  </div>
              ) : null}
          </div>
        </div>
      </div>
    </StyledCart>
  );
};

export default Cart;

const StyledCart = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  //t a b l e   line items>>>>>
  .empty-cart {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
  }

  .Line_item {
    margin-top: 30px;
  }
  
  .table-container {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    overflow-y: hidden;
    max-width: 100%;
    }
  .table {
    display: table;
    border-color: grey;

    border-collapse: collapse;
    border-spacing: 0;
    background-color: #fff;
    color: #363636;


    &.is-fullwidth {
      width: 100%;
    }

    th {
      border: solid #dbdbdb;
      border-width: 0 0 1px;
      padding: .5em .75em;
      vertical-align: top;
    }

    thead {
      display: table-header-group;
      vertical-align: middle;
      border-color: inherit;
      background-color: transparent;

       > tr {
        // border-width: 0 0 2px;
        border-bottom: 1px solid #dbdbdb;
        // color: #363636;
       }
    }


    tr {
      font-weight: 100 !important;
      display: table-row;
      vertical-align: inherit;
      border-color: inherit;
      text-align: -webkit-center;
      transition: 0.2s opacity ease-out;
      &.opaque {
        opacity: 0.1;
      }
      &:last-child th {
        border-bottom-width: 0;
      }

      th {
        border: solid #dbdbdb;
        border-width: 0 0 1px;
        padding: .5em .75em;
        vertical-align: top;

        &:not([align]) {
          text-align: left;
        }
       

        .columns {
          margin-left: -.75rem;
          margin-right: -.75rem;
          margin-top: -.75rem;


          &:last-child {
            margin-bottom: -.75rem;
          }
          &:not(.is-desktop) {
            display: flex;
          }

          hr {
            border : 0;
          }

          .column {
            display: block;
            flex-basis: 0;
            flex-grow: 1;
            flex-shrink: 1;
            padding: .75rem;

            .image {
              display: block;
              position: relative;

              &.is-96x96 {
                height: 96px;
                width: 96px;
              }
            }
          }
        }



      }
    }

    .image {
      overflow: hidden;

      img {
        max-height: 100%;
        object-fit: contain;
      }
    }
    .product-title {
      font-size: 14px;
      line-height: 15px;
      font-weight: 100;

    }
    .product-desc {
      font-size: 12px;
      margin-top: 10px;
      font-weight: 100;

    }
    tbody, tr, th {
      font-weight: 100 !important;
    }
  }

.Line_itemMobile {
  display: none;
}

  @media only screen and (max-width: ${vars.mobile}) {
    .Line_item {
      display: none;
    }

    .Line_itemMobile {
      display: block;
    }
  }
  .cart-container {
    pointer-events: none;


    .close-cart-area {
      height: 100vh;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: fixed;
      top: 0;
      transition: 0.2s opacity ease-out;
      width: 100vw;
      z-index: 100;
      background-color: rgba(255,255,255,0.8);

      &.is-visible {
        opacity: 1;
      }
    }

    .cart-modal {
      box-sizing: border-box;
      background-color: white;
      height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      padding: 0 20px 40px;
      position: fixed;
      right: 0;
      top: 0;
      transform: translateX(100%);
      transition: 0.3s all ease-out;
      width: 600px;
      max-width: 100vw;
      z-index: 200;
      pointer-events: none;
      border-left: 1px solid #dadada;


      @media only screen and (max-width: ${vars.mobile}) {
        transform: translateX(0) translateY(-10%);
        opacity: 0;
        border: 0;
        height: auto;
        border-bottom: 1px solid #dadada;
      }

      .cartHeart {
        @media only screen and (max-width: ${vars.mobile}) {
          display: none;
        }
      }

      .adding-view {
        pointer-events: none;
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        left: 0;
        align-items: center;
        justify-contents: center;
        background-color: rgba(255,255,255,0.95);
        opacity: 0;
        transition: 0.4s opacity ease-out;
        z-index: 9999;
        font-size: 2rem;
        p {
          text-align: center;
          width: 100%;
        }

        &.is-visible {
          opacity: 1;
        }
      }

      .cart-header {
        position: relative;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: ${vars.mobile}) {
          .cart-heart-container {
            display: none;
          }
        }

        .cart-title {
          position: absolute;
          left: 0;
          font-size: 16px;
          text-transform: uppercase;
        }

        .close-cart {
          background-color: transparent;
          position: absolute;
          right: -10px;
          width: 23px;
          height: 22px;

          &:hover {
            cursor: pointer;
          }

          &:before, &:after {
            position: absolute;
            left: 11px;
            top: 1px;
            content: ' ';
            height: 20px;
            width: 1px;
            background-color: black;
          }
          &:before {
            transform: rotate(45deg);
          }
          &:after {
            transform: rotate(-45deg);
          }
        }

        .cart-heart-container {
          position: relative;
          top:0;
          right: 0;
          margin-left: 0;
        }
      }
    }

    &.is-open {
      pointer-events: all;

      .cart-modal {
        pointer-events: all;
        transform: translateX(0);

        @media only screen and (max-width: ${vars.mobile}) {
          transform: translateX(0) translateY(0);
          opacity: 1;
        }
      }

      .close-cart-area {
        pointer-events: all;
        opacity: 1;

        :hover {
          cursor: pointer;
        }
      }
    }
  }

  .order-summary-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    width: 100%;

    .shippingText{
      line-height: 18px;
    }

    .checkoutButton {
      color: white;
      font-weight: normal;
      height: 36px;
      box-sizing: border-box;
      margin-top: 10px;
      background-color: ${vars.btn_color};
      
    }

    @media only screen and (max-width: ${vars.mobile}) {
    .checkoutButton {
        // font-size: 45px;
        letter-spacing: -0.9px;
      }
    }
  
    > div {
      max-width: 220px;
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;

      .row {
        padding-bottom: 15px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;
