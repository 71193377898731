import { useStaticQuery, graphql, Link } from "gatsby"; /* eslint-disable */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image";
import Menu from "./menu";
import MobileNav from "./mobile/mobileNav";
import * as vars from '../variables.js';


const Header = () => {
  const [path, setPath] = useState('/');

  useEffect(() => {
    if (window) {
      const path =  window.location.pathname.replace(/[/]/g, '');
      setPath(path);
    }
  });

  const imgData = useStaticQuery(graphql`{
  file(relativePath: {eq: "emiledrescher.png"}) {
    childImageSharp {
      gatsbyImageData(width: 500, placeholder: NONE, layout: CONSTRAINED)
    }
  }
}`);
  return <>
  <StyledNavWrapper>
    <nav className={`nav-bar ${path !== "" ? "transparent" : ""}`} role="navigation" aria-label="main navigation">
      <div className="logo">
        <Link aria-label="home" to="/">
          <GatsbyImage image={imgData.file.childImageSharp.gatsbyImageData} alt="Emile Drescher" />
        </Link>
      </div>
      <Menu path={path}/>
      <MobileNav path={path}></MobileNav>
    </nav>
  </StyledNavWrapper>
  </>;
}

export default Header;
const StyledNavWrapper = styled.div`
  height: 50px;

  .nav-bar {
    background-color: rgba(255,255,255,0.9);
    display: flex;
    font: 18px galvji;
    height: 50px;
    justify-content: space-between;
    padding: 0 10px;
    position: fixed;
    align-items: center;
    top:0;
    width: 100%;
    max-width: 100vw;
    z-index: 10;
    box-sizing: border-box;

    &.transparent {
      background-color: transparent;
      @media only screen and (max-width: ${vars.mobile}) {
        background-color: rgba(255,255,255,0.9);
      }
    }


    .logo {
      width: 200px;
      position: relative;
      display: block;
      z-index: -1;

    }

    @media only screen and (min-width: ${vars.mobile}) {
    background-color: rgba(255,255,255,0);
      .logo {
        width: 300px;
        z-index: 999;
      }
    }
  }
`;