import React, { useState } from "react";
import styled from "styled-components";
import * as vars from "../variables";

const Subscribe = () => {
  const [subscribeDropDown, setSubscribeDropDown] = useState(false);

  const subscribeClick = () => {
    setSubscribeDropDown(!subscribeDropDown);
  }

  return (
    <StyledSubscribe>
      <div className="subscribe">
        <button className={subscribeDropDown ? "subscribe-btn is-active" : "subscribe-btn"} onClick={subscribeClick} aria-label="Subscribe to Mailing List"></button>
          <div className={subscribeDropDown ? "mailchimpSub is-active" : "mailchimpSub"}>
            <form action="//harrywere.us11.list-manage.com/subscribe/post?u=b2ab4fe94f5f19e91c979ecaf&amp;id=74498e7656" method="post" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate="">
              <label className="email-field"> Email address
                <input className="required email" name="EMAIL" size="30" type="email" placeholder="Enter your email"></input>
              </label>
              <label className="button-field">
                <input className="button" name="subscribe" type="submit" value="Enter" onClick={() => {window.dataLayer.push({'event': 'subscribe_click'})}}></input>
              </label>
            </form>
          </div>
      </div>
      <div className={subscribeDropDown ? "sub-close-area is-active" : "sub-close-area"} onClick={subscribeClick}></div>
    </StyledSubscribe>
  );
};

export default Subscribe;

const StyledSubscribe = styled.div`
.subscribe {
  position: relative;
  padding: 0 50px;
  z-index: 2;

  font-size: 14px;
  text-transform: uppercase;
  @media only screen and (max-width: 1000px) {
    padding: 0 30px;
  }
  @media only screen and (max-width: ${vars.mobile}) {
    position: absolute;
    right: 0px;
    padding: 20px 0 0;
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
  }
  
  .subscribe-btn {
    background-color: transparent;
    border: 0;
    right: 0;
    width: 80px;
    outline: none;
    color: black;
    font-weight: normal;
    text-transform: uppercase;
    padding: 0;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    

    @media only screen and (max-width: ${vars.mobile}) {
      font-size: 14px;
      background-color: transparent;
      border: 0;
      right: 15px;
      width: 80px;
      outline: none;
      position: absolute;
      color: black;
      font-weight: normal;
      text-transform: uppercase;
      text-align: right;
      padding: 0;
      &:hover {
        text-decoration: none;
      }
    }
    
  &:before {
      content: "Subscribe"
    }

  &.is-active {
    &:before {
      content: "Close"
    }
  }
  }
}

.mailchimpSub {
  font-family: galvji;
  height: 60px;
  max-height: 0;
  max-width: 300px;
  overflow: hidden;
  position: absolute;
  right: 46px;
  text-align: center;
  top: 33px;
  transition: 0.4s max-height ease-out;
  width: 320px;

  label {
    overflow: visible;

    input {
      box-sizing: border-box;
      height: 32px;
    }

    &.email-field {
      flex-grow: 1;
    }

    &.button-field {
      width: 80px;

      .button {
        justify-content: center;
      }
    }

    visibility: hidden;
    input {
      visibility: visible;
    }
  }

  &.is-active {
    max-height: 40px;
  }
  
  form {
    display: flex;
    flex-direction: row;
  }

  @media only screen and (max-width: ${vars.mobile}) {
    top: 40px;
    width: 100%;
    right: 0;
  }
}

.mailchimpSub label input {
  border: medium none;
  color: black;
  border: 1px solid #dfdfdf;
  margin-bottom: 10px;
  padding: 8px 10px;
  font-family: galvji;
  position: absolute;
}

.mailchimpSub label input.email { 
  flex-grow: 1; 
  background: #fff; 
  border-right: 0;
  width: 100%;
  left: 0;
}
.mailchimpSub label input[type="submit"] {
  background: #fff; 
  color: #black;
  cursor: pointer;
  font-size: 14px;
  width: 80px;
  padding: 8px 0;
  line-height: 0;
  border-radius: 0;
  text-transform: uppercase;
  font-style: italic;
  right: 0;

}

::-webkit-input-placeholder {
   color: gray;
}

.sub-close-area {
  z-index: 1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  opacity: 0;
  transition: 0.3s opacity ease-out;
  pointer-events: none;

  &.is-active {
    opacity: 1;
    pointer-events: all;  
  }
}
`;
